<div class="rightSide {{ extraComponentClass }}">
  <div class="hideTop">
    <div *ngIf="relatedItemiNews === true" class="hideTop__relatedLink">
      <p class="cursorLink" (click)="clickRelated()">{{ relatedItemiNewsTitle }} <span class="icon-sort"></span></p>
    </div>
    <div *ngIf="iNewsActivity === true">
      <div class="hideTop__title">{{ iNewsActivityTitle }}</div>
    </div>
    <div *ngIf="(headerComponentTitle && headerComponentTitle !== '')">
      <div class="header">
        <span class="{{ headerComponentIcon }}"></span> {{ headerComponentTitle }}
      </div>
    </div>
  </div>
  <div class="wrapper">
    <ng-content></ng-content>
  </div>
  <div class="hideBottom"></div>
</div>
